import React, { useEffect } from "react";
import noScroll from "no-scroll";

export default ({ children, close, ...rest }) => {
  useEffect(() => {
    noScroll.on()
    return () => noScroll.off()
  })

  return (
    <div className="Modal" onClick={close}>
      <div className="Modal-inner" onClick={event => event.stopPropagation()}>
        {children}
      </div>
    </div>
  )
}