export const landingQuery = `
  entries(section: "home") {
    ...on home_home_Entry {
      title
      header {
        ...on header_BlockType {
          heading
          text
        }
      }
      graphics {
        id
        ...on graphics_graphic_Entry {
          message
          fontFamily
          defaultColorCombo {
            ...on colorCombos_combo_Entry {
              id
            }
          }
          defaultImage {
            id
            typeHandle
            ...on pieces_icon_Entry {
              id
              image {
                id
                url
              }
            }
            ...on pieces_tapestry_Entry {
              id
              imageVariants {
                ...on imageVariants_BlockType {
                  horizontal {
                    id
                    url
                  }
                  vertical {
                    id
                    url
                  }
                }
              }
            }
          }
        }
      }
      wallpapersCta {
        ...on wallpapersCta_BlockType {
          heading
          subheading
          cta
          image {
            url
          }
        }
      }
      wallpapers {
        id
        ...on wallpapers_wallpaper_Entry {
          id
          wallpaperSizes {
            ...on wallpaperSizes_BlockType {
              desktop {
                id
                url
              }
              facebook {
                id
                url
              }
              letter {
                id
                url
              }
              mobile {
                id
                url
              }
              twitter {
                id
                url
              }
            }
          }
        }
      }
      footer {
        ...on footer_BlockType {
          heading
          links {
            ...on links_linkUrl_BlockType {
              id
              text
              linkUrl
            }
          }
        }
      }
    }
  }
  __schema {
    types {
      name
      description
    }
  }`
;

export const piecesQuery = `
  entries(section: "pieces") {
    id
    typeHandle
    ...on pieces_icon_Entry {
      id
      image {
        id
        url
      }
      defaultColorCombo {
        ...on colorCombos_combo_Entry {
          id
        }
      }
    }
    ...on pieces_tapestry_Entry {
      id
      imageVariants {
        ...on imageVariants_BlockType {
          horizontal {
            id
            url
          }
          vertical {
            id
            url
          }
        }
      }
    }
  }
`

const colorsQuery =  `
  entries(section: "colorCombos") {
    ...on colorCombos_combo_Entry {
      id
      backgroundColor {
        ...on colors_color_Entry {
          color
        }
      }
      textColor {
        ...on colors_color_Entry {
          color
        }
      }
    }
  }
`


export const allQuery = `{
  landing: ${landingQuery}
  pieces: ${piecesQuery}
  colors: ${colorsQuery}
}`