import React, { useState, useEffect } from "react";
import Graphics from "components/Graphics"
import EditGraphic from "components/EditGraphic";
import DownloadGraphic from "components/DownloadGraphic";
import "./Landing.scss";

const checkMobile = () => !window.matchMedia(`(min-width: 768px)`).matches

export default ({
  landing,
  pieces,
  colors
}) => {
  const [edit,set] = useState()
  const [download,setDownload] = useState()
  const {
    header,
    footer,
    graphics,
    wallpapersCta,
    wallpapers
  } = landing[0]

  const [isMobile,setIsMobile] = useState(checkMobile())
  useEffect(() => {
    const onResize = () => {
      setIsMobile(checkMobile())
    }
    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("resize",onResize)
    }
  });

  colors = colors.reduce((r,d) => {
    r[d.id] = {
      bg: d.backgroundColor[0].color,
      text: d.textColor[0].color
    }
    return r
  },{})

  return <div className="Landing">
    <Section
      heading={header[0].heading}
      subheading={header[0].text}>
      <Graphics
        items={graphics}
        setEdit={set}
        pieces={pieces}
        colors={colors}
        size={isMobile ? "twitter" : "instagram"} />
      {edit && <EditGraphic {...edit} setEdit={set} pieces={pieces} colors={colors} />}
    </Section>
    <Section
      heading={wallpapersCta[0].heading}
      subheading={wallpapersCta[0].subheading}>
      <Wallpapers items={wallpapers} setDownload={setDownload} />
      {download && <DownloadGraphic current={download} setDownload={setDownload} items={wallpapers} />}
    </Section>
    <footer className="Footer">
      <div className="Footer-heading">{footer[0].heading}</div>
      <div className="Footer-links">
        {footer[0].links.map(({ id, linkUrl, text }) => (
          <a key={id}
            className="Footer-links-item"
            href={linkUrl}>
            {text}
          </a>
        ))}
      </div>
    </footer>
  </div>
}

const Section = ({ heading, subheading, children }) => <section className={"Section"}>
  <header className="Landing-header">
    <div className="Landing-header-inner">
      <h1 className="Landing-header-heading">{heading}</h1>
      <div className="Landing-header-text">{subheading}</div>
    </div>
  </header>
  <main className={"Landing-main"}>
    {children}
  </main>
</section>

const Wallpapers = ({ items, setDownload }) => {
  return <div className={"Graphics"}>
    {items.map(item => {
      return (
        <button
          key={item.id}
          className="Graphic Graphic--wallpaper"
          onClick={() => {
            setDownload(item.id)
          }}>
          <img src={item.wallpaperSizes[0]['desktop'][0].url} alt={""} />
        </button>
      )
    })}
  </div>

}