import React, { useReducer } from "react";
import Modal from "components/Modal";
import { Sizes} from "./EditGraphic";
import { ReactComponent as X } from "svg/x.svg";

const sizes = {
  "facebook": {
    size: [1640,624]
  },
  "desktop": {
    size: [1920,1080]
  },
  "letter": {
    size: [612,792]
  },
  "mobile": {
    size: [1080,1920]
  },
  "twitter": {
    size: [1500,500]
  }
}

const reducer = (state,action) => {
  switch (action.type) {
    case "setImage":
      return {
        ...state,
        image: action.value
      }

    case "setSize":
      return {
        ...state,
        size: action.value
      }

    case "setDownload":
      return {
        ...state,
        download: action.value
      }

    default:
      throw new Error(`Unknown action type ${action.type}`)

  }
}


export default ({ current, setDownload, items }) => {
  const [state,dispatch] = useReducer(reducer,{
    image: current,
    size: 'facebook'
  })
  const currentItem = items.find(item=>item.id ===state.image) || items[0]
  const close = () => {
    setDownload(null)
  }
  return <Modal close={close}>
    <div className="EditGraphic-inner-grid">
      <div className={"EditGraphic-result"}>
        <div className={"Canvas"}>
          <img src={currentItem.wallpaperSizes[0][state.size][0].url} alt={""}/>
        </div>
        <div className="EditGraphic-submit">
          <a className="EditGraphic-download"
            href={currentItem.wallpaperSizes[0][state.size][0].url}
            download>Download</a>
        </div>
      </div>
      <div className="Modal-inner-close">
        <button className="Modal-inner-close-button" onClick={close}>
          <X />
        </button>
      </div>
      <div className="EditGraphic-controls">
        <form>
          {/*<Illustrations*/}
          {/*  pieces={items}*/}
          {/*  current={state.image}*/}
          {/*  set={value => {*/}
          {/*    dispatch({*/}
          {/*      type: "setImage",*/}
          {/*      value*/}
          {/*    })*/}
          {/*  }} />*/}
          <Sizes
            sizes={sizes}
            current={state.size}
            set={value => {
              dispatch({
                type: "setSize",
                value
              })
            }} />
        </form>
      </div>
    </div>
  </Modal>
}

// const Illustrations = ({ pieces, current, set }) => {
//   return <div className="Illustration">
//     <div className="EditGraphic-control-label">Choose Colorway</div>
//     <div className="Illustration-choices Illustration-choices--tapestry">{pieces.map((piece,i) => {
//       return <label key={piece.id} className="Illustration-choices-item">
//         <input
//           type="radio"
//           name="illustration"
//           checked={(!current && i===0) || current===piece.id }
//           onChange={() => {
//            set(piece.id)
//           }} />
//           <div className={"Illustration-choices-item-label"}>
//             <img src={piece.wallpaperSizes[0]["facebook"][0].url} alt="" />
//           </div>
//       </label>
//     })}</div>
//   </div>
// }

// const Sizes = ({ current, set }) => {
//   return <div className="Sizes">
//     <div className="EditGraphic-control-label">Select Size</div>
//     <div className="Sizes-choices">{Object.keys(sizes).map(key => {
//       const {size} = sizes[key]
//       return <label key={key} className="Sizes-choices-item">
//         <input
//           type="radio"
//           name="Sizes"
//           checked={current===key }
//           onChange={() => {
//            set(key)
//           }} />
//         <div>
//           <div>{key}</div>
//           <svg viewBox={`0 0 ${size[0]} ${size[1]}`} style={{
//             display: "block",
//             width: "75px"
//           }}>
//             <rect width={size[0]} height={size[1]} fill={"#dadada"} />
//             <text
//               dominantBaseline={"middle"}
//               textAnchor={"middle"}
//               x={size[0]/2}
//               y={size[1]/2}
//               fill={"black"}
//               fontSize={size[0]/10}>
//               {`${size[0]} x ${size[1]}`}
//             </text>
//           </svg>
//         </div>
//       </label>
//     })}</div>
//   </div>
// }