import React from "react";
import HomeLink from "components/HomeLink";
import "./Nav.scss";

export default () => {
  return (
    <nav className="Nav">
      <div className="Nav-inner">
        <HomeLink /> <div className="Nav-separator" /> Design Playground
      </div>
    </nav>
  )
}